import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import NotFound from '../components/NotFound/NotFound';

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title="404: Not Found" />
      <NotFound />
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
