import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import NotFoundBird from '../../../content/assets/404Bird.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      paddingTop: '80px',
      paddingBottom: '244px',
    },
    container: {
      display: 'flex',
      color: '#004F71',
      '& #notfound-wrapper': {
        display: 'flex',
        height: '376px',
        width: '559px',
        backgroundImage: `url(${NotFoundBird})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
        margin: 'auto',
        '& #content': {
          width: '443px',
          margin: 'auto auto 0 115px',
        },
      },
      '& h1': {
        margin: 0,
        fontSize: '48px',
        fontWeight: 300,
        lineHeight: '58px',
      },
      '& h2': {
        margin: 0,
        fontSize: '22px',
        fontWeight: 'normal',
        lineHeight: '30px',
        marginBottom: '40px',
      },
      '& #home-link': {
        color: '#F8B133',
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {},
    },
    [theme.breakpoints.down(501)]: {
      root: {
        paddingTop: '40px',
        paddingBottom: '160px',
      },
      container: {
        padding: '0 11.15%',

        '& #notfound-wrapper': {
          height: '260px',
          width: '279px',
          backgroundSize: '200px 190px',
          '& #content': {
            margin: 'auto 0 0 71px',
          },
        },

        '& h1': {
          fontSize: '30px',
          lineHeight: '37px',
          marginBottom: '7px',
        },
        '& h2': {
          fontSize: '14px',
          lineHeight: '18px',
          marginBottom: '28px',
        },
        '& #home-link': {
          color: '#F8B133',
          fontSize: '13px',
          lineHeight: '18px',
        },
      },
    },
  })
);
export default useStyles;
