import React from 'react';
import useStyles from './NotFound.styles';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'gatsby';
const NotFound = () => {
  const classes = useStyles();
  return (
    <section id="" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <div id="notfound-wrapper">
          <div id="content">
            <h1>Phew... phew</h1>
            <h2>¡Voló a otro lugar este contenido!</h2>
            <Link id="home-link" to="/">
              Ir a CurrencyBird
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default NotFound;
